import React from 'react'

import './solvSureActivePolicy.scss'

const SolvSureActivePolicy = () => {
    return (
        <>
	    <div className='main-content'>
		    <div className='title'>
                <span id="newLine">SOLV SURE ACTIVE CASHBACK / DISCOUNT</span>
                <br/>
                <span id="newLine">OFFER TERMS & CONDITIONS</span>
            </div>
            <p className='sub-heading'>DEFINITIONS</p>
            <p>For the purpose of these terms and conditions (“<b>Terms and Conditions</b>"):</p>
            <ul style={{"listStyle":"disc"}}>
                <li>
                    <p><b>“User”</b> shall mean a business entity that has On-Boarded on the Solv Platform using its legal name and mobile number, and is legally subsisting during the Offer Period;</p>
                </li>
                <li>
                    <p><b>“Eligible User”</b> shall mean (i) an On-Boarded User who has purchased ‘Solv Sure Active’ product, a bouquet of service package in preventive health and content insurance, which Solv is offering through third party service providers; and (ii) who has purchased products of net amount value (post discount applied, if any) INR 50,000 or above from sellers on the Solv Platform during the timeframe of 12 months following purchase of Solv Sure Active subscription.; </p>
                </li>
                <li>
                    <p><b>“Offer”</b> shall mean the cashback, discount or other benefits offered to the Eligible Users during the Offer Period subject to the terms and conditions as set out herein;</p>
                </li>
                <li>
                    <p><b>“Offer Period”</b> shall mean the period of 12 (twelve) months succeeding the date of purchase of Solv Sure Active product by a User from Solv, which may be amended by Solv at any time;</p>
                </li>
                <li>
                    <p><b>“On-Board” or “On-Boarding” </b> means the downloading and installation of the Solv application by the User, providing relevant Merchant Information to Solv, successful verification of Merchant Information and registration of User as a seller/buyer on the Solv Platform by obtaining a unique Solv identification number</p>
                </li>
                <li>
                    <p><b>“Primary Terms and Conditions”</b> shall mean the terms and conditions governing the usage of the Solv platform, as applicable to the Users as separately provided on the Solv application and/or portal in addition to these Terms and Conditions set out herein. </p>
                </li>
            </ul>
            <p>Any capitalised terms used in these Terms and Conditions, however not defined herein, shall have the same meanings as attributed to such terms in the Primary Terms and Conditions.</p>
            
            <p className='sub-heading'>OFFERS</p>    
            <p>The Eligible Users who have subscribed Solv Sure Active shall be eligible to receive cash back for the entire subscription price of INR 1500 which was paid towards purchasing Solv Sure Active in the form of a discount voucher activated on the relevant Solv ID. Such discount voucher can be redeemed by the User against any subsequent transaction on the Solv Platform within a period of 12 months from the date of receipt of voucher.</p>


            <p className='sub-heading'>OFFER DETAILS AND CONDITIONS:</p>
            <ul style={{"listStyleType":"lower-alpha"}}>
                <li>
                    <p> 
                        The discount available to the Eligible Users through this Offer can only be applied on specific commercial transactions initiated by such Users towards purchasing products from sellers on Solv platform and not towards availing any financial services.
                    </p>
                </li>
                <li>
                    <p> 
                        Towards calculating the aggregate purchase value of INR 50,000 to be achieved by any User for availing the Offer, the following factors shall be applicable:
                    </p>
                    <ul style={{"listStyleType":"lower-roman"}}>
                        <li>
                            <p>	The accumulation of multiple orders placed by Users post purchase of Solv Sure Active subscription is allowed subject to fulfilling other criterion of individual orders on Solv platform (Eg. Min Order value of each order). </p>
                        </li>
                        <li>
                            <p>Only successfully delivered orders will be taken for consideration in the calculation of transaction value for the Offer. Any return/cancellation transaction will not be accounted. 
                                Illustration 1: In case the first order placed by a User is of value INR 50,000 or above, however, the seller is only able to fulfill order of value INR 48,000, the fulfilled value of INR 48,000 will be taken for accumulation. The User can place another order of INR 2000 or multiple orders with Solv’s minimum order value till the order accumulation reaches INR 50,000 on net.
                                Illustration 2: In case the first order placed by User is of value INR 50,000 and Seller was able to fulfill order value of INR 50,000, however, due to damage/ or any applicable reason, part of the order value is returned amounting to INR 25,000, only the fulfilled order value of INR 25,000 will be taken for accumulation. The User can thereafter place another order of INR 25,000 or multiple orders satisfying Solv’s minimum order value till the order accumulation reaches INR 50,000 on net.
                                </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        In case any User completes transaction value INR 50,000 on successfully delivered orders before the 12-month time frame, the discount can be activated post the return/replace window is closed.
                    </p>
                </li>
                <li>
                    <p>
                        The minimum order value for redeeming the discount voucher in any transaction should at least be equivalent to the discount value. 
                    </p>
                </li>
                <li>
                    <p>
                        The Offer is open to the On-Boarded Users in the regions of Bangalore, Delhi NCR (including Gurgaon and Faridabad) and Hyderabad. 
                    </p>
                </li>
                <li>
                    <p>
                        The participation in the Offer is entirely voluntary and it is understood that the participation by the Users shall be deemed to have been made on a voluntary basis.
                    </p>
                </li>
                <li>
                    <p>
                        In all matters relating to the Offer, including the application of discount, the decisions of Solv shall be final and binding.
                    </p>
                </li>
                <li>
                    <p>
                        Any person availing this Offer shall be deemed to have accepted these Terms. The Users shall continue to be bound to comply with the Primary Terms and Conditions and these Terms shall be in addition to and not in substitution /derogation to the Primary Terms and Conditions governing the usage of Solv services.
                    </p>
                </li>
                <li>
                    <p>
                        Solv reserves the right to modify at any time, all or any of the terms applicable to the Offer, including the terms contained herein, without assigning any reasons or without any prior intimation whatsoever. Solv also reserves the right to discontinue or terminate the Offer without assigning any reasons or without any prior intimation whatsoever at any time during the Offer Period.
                    </p>
                </li>
                <li>
                    <p>
                        The Eligible Users shall not be entitled to assign the rights and benefits arising under this Offer to any other person. No requests for transfer or assignment of the benefits under the Offer shall be entertained.
                    </p>
                </li>
                <li>
                    <p>
                        By participating in this Offer, the User expressly agrees that Solv or any of its affiliates will not be liable or responsible for any loss or damage whatsoever that you may suffer, directly or indirectly, in connection with this Offer, including but not limited to that associated with his/her use or delivery or misuse of any promotional codes and/or benefit provided hereunder. In the event Solv suffers any loss or damage arising out of or resulting from any inaccurate information provided by the User or breach of any applicable law or terms and conditions, the User shall be liable to indemnify Solv for all the loss and damage incurred.
                    </p>
                </li>
                <li>
                    <p>
                        Under no circumstances will the benefit being offered under this Offer be provided in cash by Solv, i.e. there are no cash or other alternatives available in whole or in part, in relation to the benefits under this Offer.
                    </p>
                </li>
                <li>
                    <p>
                        Solv reserves the right to disqualify any merchant establishment or User from the benefits of the Offer and initiate appropriate legal action, if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the Offer. Further, Solv reserves the right to deny honouring the Offer or any benefit thereof on the grounds of suspicion or abuse of the Offer by any User without providing the User any explanation thereof. 
                    </p>
                </li>
                <li>
                    <p>
                        The Offer shall not be available in any jurisdiction in which providing such Offer is prohibited under applicable laws or otherwise for any reason whatsoever.
                    </p>
                </li>
                <li>
                    <p>
                        Any queries or disputes from Users regarding issuance/denial of discount, can only be raised within 30 days from the date of expiry of Offer Period. 
                    </p>
                </li>
                <li>
                    <p>
                        All communication and notices about this Offer should be addressed to&nbsp;
                        <a className='mail-anchor' href="mailto:cs@solvezy.com">cs@solvezy.com</a>
                    </p>
                </li>
                <li>
                    <p>
                        All disputes are subject to the exclusive jurisdiction of the competent courts/tribunals of Bangalore.
                    </p>
                </li>
            </ul>
		</div>
	</>
    )
}

export default SolvSureActivePolicy
