import React from 'react'

import styles from "./../../../policies-css-module.module.scss"
import '../../../../styles.scss'

import { Layout } from '../../../../components'

import SolvSureActivePolicy from '../../../../components/policies/solvSureActivePolicy/solvSureActivePolicy'

import { Link } from "gatsby"

const SolvSureActiveOffer = () => {
	return (
		<>
			<Layout>
					<div className={styles.container}>
						<div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/promos/solvsureactiveoffer/tnc/'>SOLV SURE ACTIVE</Link>
							</div>
						</div>
						<div className={styles.content_section}>
								<SolvSureActivePolicy />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default SolvSureActiveOffer
